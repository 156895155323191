.bannerSection {position: relative;max-height: 640px;overflow: hidden;height: 100%;}
.bannerImage{position: relative;width: 100%;height: 640px;}
.bannerDetails.newBanner + .bannerImage{background: #b72928;z-index: 1;}
.bannerDetails.newBanner + .bannerImage .bannerImg{object-fit: initial;}
/* .bannerImage span{width: 100% !important;} */
.bannerSection .bannerImg{object-fit:cover;position: absolute;z-index: -1;height: 100%;width: 100%;}
.bannerSection .bannerDetails{position: absolute;left: 0;top: 0;right: 0;bottom: 0;max-width: 1140px;margin: 0 auto;width: 100%;display: flex;justify-content: center;align-items: flex-end;height: calc(100% - 70px);}
.bannerSection .bannerDetails.newBanner{height: calc(100% - 44px);justify-content:flex-end;}
.bannerSection .bannerDetails.newBanner .bannerCtaButton{color: #b72928;background: #fff;font-weight: 600;border-radius: 50px;margin: 0 62px 20px;padding: 0px 20px;line-height: 40px;}
.bannerSection .mainHeading{display: flex;margin-bottom: 22px;color: #fff;font-size: 46px;line-height: 55px;font-weight: 700;}
.bannerSection .subHeading{margin-bottom: 24px;color: #fff;font-size: 16px;}
.bannerDetails .mainPrimaryBtn{padding: 0px 35px;line-height: 50px;font-size: 18px;position: relative;z-index: 2;}
.bannerSection .noCostEmi{position: absolute;right: 0;top: 60px;max-width: 150px;}
.aeBannerSection .bannerDetails{height: 100%;flex-direction: column;align-items: flex-start;justify-content: center;}
.aeBannerSection .mobWidth{max-width: 500px;}
.homeIndiaMainbanner .mobWidth{text-align:center;}
.paginations{position: absolute;display: flex;gap: 12px;bottom: 29px;align-items: center;justify-content: center;width: 100%;z-index: 2;}
.paginations span{width: 20px;height: 20px;cursor: pointer; display:inline-flex;border-radius:50px; border: solid 2px transparent; position: relative; align-items: center;justify-content: center;}
.paginations span::after{content: '';width: 14px;height: 14px;border-radius: 10px;background: hsla(0,0%,100%,.5);display: block;}
.paginations span.active{ border: solid 2px #fff;}
.paginations span.active::after{ background:#fff ;}

@media screen and (max-width:1920px) {
    .bannerSection .bannerDetails.newBanner .bannerCtaButton{margin: 0 12px 31px;}
}
    
@media screen and (max-width:1630px) {
    .bannerSection .bannerDetails{height: calc(100% - 100px);}
    .bannerSection .bannerDetails.newBanner .bannerCtaButton{margin: 0 80px 31px;}
}
@media screen and (max-width:1280px) {
    .sliderButtonBx .backBtn{left: 10px;}
    .sliderButtonBx .nextBtn{right: 10px;}
.bannerSection .bannerDetails.newBanner .bannerCtaButton{margin: 0 125px 25px;}
}
@media screen and (max-width:1024px) {
    .bannerSection .bannerDetails.newBanner .bannerCtaButton{margin: 0 125px 30px;}
}

@media screen and (max-width:840px) {
.bannerSection .bannerDetails{padding: 0px 15px;left: 0;bottom: 60px;text-align: left;width: 100%;}
.bannerSection .subHeading{margin-bottom: 15px;}
.bannerSection .bannerDetails.newBanner .bannerCtaButton{margin: 0 90px 40px;font-size: 14px;padding: 10px;line-height: 14px;}
}

@media screen and (max-width:690px) {
    .bannerSection .bannerDetails.newBanner .bannerCtaButton{margin: 0 58px 30px;}
    }
@media screen and (max-width:540px) {
.mobWidth{max-width: 230px;margin: 0 auto;text-align: center;}
.bannerImage{height: 640px;}
.bannerSection {max-height:640px;height: 100%;}
.bannerSection .bannerDetails{padding: 30px 15px 60px;height: calc(100vh - 90px);max-height: 665px;justify-content: space-between;}
.bannerDetails .mainPrimaryBtn{width: 100%;line-height: 40px;white-space: nowrap;}
.bannerSection .mainHeading{font-size: 40px; line-height: 50px; font-weight: 900;}
.indiaMobile.bannerSection .mainHeading{margin-bottom: 11px;font-size: 30px;max-width: 100%;letter-spacing: 0.9px;font-weight: 700;line-height: 45px;}
.indiaMobile.bannerSection .bannerDetails{padding: 20px 15px 90px;display: flex;bottom: 0px;right: 0;left: 0;top: auto;height: 100%;max-width: 100%;max-height: calc(100% - 0px);}
.indiaMobile.bannerSection .bannerImg{object-fit: cover;}
.indiaMobile .mobWidth{max-width: 100%;}
.indiaMobile.bannerSection .subHeading{margin: 0px auto 45px;letter-spacing: 0.2px;line-height: 25px;}
.indiaMobile.bannerSection .noCostEmi{right: 15px;top: 15px;bottom: inherit;max-width: 100px;left: initial;}
.bannerCtaButton{margin: 0 auto;max-width: 300px;display: block;}
.aeBannerSection .ctaButton{width: 100%;}
.bannerSection .bannerDetails.newBanner{justify-content: center;padding: 0;bottom: 95px;}
.bannerSection .bannerDetails.newBanner .bannerCtaButton{margin: 0px;padding: 7px 16px;width: 205px;line-height: 22px;white-space: normal;}
/* .homeIndiaMainbanner .bannerDetails{background-image: linear-gradient(rgba(225,225,225,0), rgba(0,0,0,0.5));} */
}

@media screen and (max-width:480px) {
    .bannerSection .bannerDetails.newBanner{bottom: 75px;}
}
@media screen and (max-width:360px) {
    .indiaMobile.bannerSection .bannerDetails{padding: 0 15px;max-width: 100%;bottom: 0;max-height: 100%;}
    .indiaMobile.bannerSection .mainHeading{font-size: 28px;}
    .indiaMobile.bannerSection .noCostEmi{right: 15px;}
    .indiaMobile .mobWidth{position: relative;bottom: 70px;}
    .indiaMobile .kidsBannerDetails  .bannerDetails{align-items: flex-start;}
    .bannerSection .mainHeading{font-size: 26px;}    
    .aeBannerSection .mainHeading{font-size: 40px;}
    .bannerSection .bannerDetails.newBanner{bottom: 72px;}
}

